
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'

import TerminalVue, { XtermVueInterface } from '@/components/xterm-vue/XtermVue.vue'
import terminalConfig from '../maintain/logger-center/log-console/terminal.config'

@Component({
  name: 'testDeploy',
  components: { TerminalVue }
})
export default class TestDeploy extends Vue {
  /// 任务处理状态
   taskProcStatus: {
     loading: boolean;
     term?: XtermVueInterface;
   }={
     loading: false
   }

   /// 终端配置
  terminalOption = terminalConfig.terminalOption;

  /**
   * 控制台准备完成
   */
  onTerminalReady () {
    this.taskProcStatus.term = (this.$refs.term as any) as XtermVueInterface
  }

  testDelploy () {
    this.taskProcStatus.loading = true
    this.addMessageLineToTerm('向服务器申请部署数据....')
  }

  /**
   * 向控制台添加消息
   * @param message
   */
  private addMessageLineToTerm (message: string) {
    if (this.taskProcStatus.term) this.taskProcStatus.term.addLineMessage(message)
  }
}
