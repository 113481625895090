import { ITerminalOptions } from 'xterm'
const terminalOption: ITerminalOptions = {
  lineHeight: 1.2,
  disableStdin: true,
  scrollback: 25,
  fastScrollModifier: 'alt',
  fontSize: 14,
  cursorStyle: 'underline',
  logLevel: 'debug',
  screenReaderMode: true
  // fontFamily: "'Cascadia Code',monospace"
}

export default {
  terminalOption
}
